<div [ngClass]="{ 'overflow-hidden': smallView }">
  <div
    class="cover-container width-100 relative sparro-white"
    [style.backgroundImage]="'url(' + featuredBulletin?.image?.url + ')'">
    <app-landing-page-header
      [smallView]="smallView"
      [user]="user"
      [contributionForm]="contributionForm"
      [competitionLaunchForm]="competitionLaunchForm">
    </app-landing-page-header>
    <div fxLayout="column" class="content-section absolute">
      <h1 class="title width-100">Accelerating Innovation through Competitions</h1>
      <p class="subtitle width-100">
        Democratizing architecture and design competition ecosystem. Participate in real-world challenges, build your portfolio, and explore innovative ideas.
      </p>
      <a
        [href]="getCompetitionFeedUrl()"
        mat-button
        class="no-decor sparro-white margin-right-2 button-section button-orange-1">
        Explore Challenges
      </a>
      <div fxLayout="row" class="explore-links">
        <a [href]="getLaunchPageUrl()" class="sparro-white margin-right-3">
          Launch a Competition
        </a>
        <div class="dot margin-right-3 margin-top-1"></div>
        <a [href]="getMembershipPageUrl()" class="sparro-white margin-right-3"> UNI Membership </a>
      </div>
    </div>
    <span
      class="credit-section sparro-grey-300 absolute font-xs"
      [innerHtml]="featuredBulletin?.info | safeContent">
    </span>
    <div class="featuredon-container">
      <ng-container *ngIf="smallView; else singleFlexView">
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages.slice(0, 4); let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages.slice(4, 8); let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #singleFlexView>
        <div class="flex-container width-100">
          <ng-container *ngFor="let image of featureImages; let i = index">
            <uni-image
              [src]="image"
              [alt]="'featureImage' + i"
              [height]="64"
              auxClassList="news-outlet-img">
            </uni-image>
          </ng-container>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="video-container">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      [fxLayoutGap]="smallView ? '40px' : '80px'"
      #videoContainer>
      <div fxLayout="column" fxLayoutAlign="center center" class="inner-container">
        <h2 class="section-title text-center">Tackle Real-World Design Challenges</h2>
        <p class="section-subtitle sparro-grey-800 text-center">
          Join a vibrant community and explore our library of over 
          <strong>500+ design challenges</strong> tailored to your interests. Get inspired 
          by projects from <strong>leading institutions worldwide</strong> and collaborate 
          online to generate design solutions from anywhere in the world.
        </p>
      </div>
      <video
        class="rounded-border height-auto"
        controls
        autoplay
        [muted]="true"
        [loop]="true"
        #videoSection>
        <source
          src="https://uni-common-assets.s3.amazonaws.com/Landingpage/3section/intro_video.mp4"
          type="video/mp4" />
      </video>
    </div>
  </div>

  <app-key-features [smallView]="smallView"> </app-key-features>

  <div class="gifs-container">
    <div fxLayout="column" fxLayoutAlign="center flex-start" class="inner-container">
      <div fxLayout="column" fxLayoutAlign="center flex-start" class="designer-heading-container">
        <h2 class="section-title margin-0">Make better design decisions with data</h2>
        <p class="section-subtitle sparro-grey-800 margin-0">
          Beyond a platform to showcase your work, UNI empowers you with 
	a <strong>prestigious certificate and detailed analytics</strong> to 
	refine your designs and excel in every competition you join.
        </p>
      </div>
      <!-- <button mat-button class="button-section button-orange-1">
                <span class="text margin-right-2">Explore more</span>
            </button> -->
    </div>
    <div class="gif-container relative">
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/Doodle1.png"
        alt="doodle1"
        [width]="150"
        [height]="150"
        auxClassList="doodle1">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/doodle2.png"
        alt="doodle2"
        [width]="150"
        [height]="150"
        auxClassList="doodle2">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/doodle3.png"
        alt="doodle3"
        [width]="120"
        [height]="120"
        auxClassList="doodle3">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/dashboard_desktop.webp"
        alt="analytics, dashboard, activity, project, competition, architecture"
        [width]="600"
        [height]="400"
        auxClassList="dashboard-desktop">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/dashboard_mobile.webp"
        alt="mobile, profile score, project image, build your portfolio"
        [width]="150"
        [height]="250"
        auxClassList="dashboard-mobile">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/avatar2.gif"
        alt="render, visual, uni projects, architecture, aqua, electric blue ocean cloud"
        [width]="350"
        [height]="250"
        auxClassList="avatar1">
      </uni-image>
      <uni-image
        src="https://uni-common-assets.s3.amazonaws.com/Landingpage/5designers/avatar1.webp"
        alt="community work, uni.xyz, renders animated, site analysis"
        [width]="150"
        [height]="150"
        auxClassList="avatar2">
      </uni-image>
    </div>
  </div>

  <app-jurors-comment [showFixedCard]="!smallView"> </app-jurors-comment>

  <div class="competition-section">
    <ng-container *ngIf="competitions?.length">
      <ng-container *ngIf="smallView; else desktopSectionView">
        <div class="image-container">
          <ng-container *ngFor="let competition of competitions.slice(0, 4)">
            <div class="image-shadow">
              <uni-competition-card [competition]="competition"> </uni-competition-card>
            </div>
          </ng-container>
        </div>
      </ng-container>
      <ng-template #desktopSectionView>
        <div class="image-container" fxFlex="50">
          <ng-container *ngFor="let competition of competitions.slice(0, 4); let i = index">
            <div [class]="'competition-card image-shadow card-' + i">
              <uni-competition-card [competition]="competition"> </uni-competition-card>
            </div>
          </ng-container>
        </div>
      </ng-template>
    </ng-container>
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" #cards>
      <div fxLayout="column" fxLayoutAlign="center flex-start" class="inner-container">
        <h2 class="section-title">Launch a competition within minutes on UNI</h2>
        <p class="section-subtitle margin-0">
          Make it even more effortless by having UNI host, manage and promote your competition. Our
          expert team of curators will work with you to create an exciting competition brief that
          will encourage designers to come up with innovative solutions.
        </p>
        <button
          mat-button
          class="button-section button-orange-2"
          (click)="openForm('competitionLaunchForm')">
          Get Started
        </button>
        <div
          fxLayout="row"
          [fxLayoutAlign]="(smallView ? 'center' : 'flex-start') + ' center'"
          class="feature-container width-100">
          <ng-container
            *ngFor="let feature of mobileView ? businessFeatures.slice(0, 2) : businessFeatures">
            <div
              fxLayout="row"
              fxLayoutAlign="flex-start center"
              [fxLayoutGap]="mobileView ? '2px' : '8px'">
              <uni-image
                [src]="feature.icon"
                alt="feature_icon"
                [height]="41"
                [width]="41"
                auxClassList="feature-image">
              </uni-image>
              <h3 class="font-sm margin-0">{{ feature.title }}</h3>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="jury-section">
    <div fxLayout="column" fxLayoutAlign="center center" fxFlex="50" #cards>
      <div fxLayout="column" fxLayoutAlign="center flex-start" class="inner-container">
        <h2 class="section-title">Share your expertise with design community</h2>
        <p class="section-subtitle margin-0">
          Join the league of <strong>2000+ experts</strong> on UNI. Curate a competition, launch a
          course or become an expert juror on UNI and <strong>get paid</strong>.
        </p>
        <button
          mat-button
          class="button-section button-blue"
          (click)="openForm('contributionForm')">
          <span class="text">Get Started</span>
        </button>
        <div
          fxLayout="row"
          [fxLayoutAlign]="(smallView ? 'center' : 'flex-start') + ' center'"
          class="feature-container width-100">
          <ng-container
            *ngFor="
              let feature of mobileView ? institutionFeatures.slice(0, 2) : institutionFeatures
            ">
            <div
              fxLayout="row"
              fxLayoutAlign="flex-start center"
              [fxLayoutGap]="mobileView ? '2px' : '8px'">
              <uni-image
                [src]="feature.icon"
                alt="feature_icon"
                [height]="41"
                [width]="41"
                auxClassList="feature-image">
              </uni-image>
              <h3 class="font-sm margin-0">{{ feature.title }}</h3>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <div class="image-container" fxFlex="50">
      <div class="counter-section">
        <div fxLayout="row" class="inner-count-container" fxLayoutAlign="flex-start center">
          <uni-image
            src="https://uni-common-assets.s3.amazonaws.com/Landingpage/8numbers/icon.svg"
            alt="competition-icon"
            [width]="64"
            [height]="64"
            auxClassList="counter-image">
          </uni-image>
          <div fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="title margin-0">500+</p>
            <p class="subtitle margin-0">Competitions</p>
          </div>
        </div>
        <div fxLayout="row" class="inner-count-container" fxLayoutAlign="flex-start center">
          <uni-image
            src="https://uni-common-assets.s3.amazonaws.com/Landingpage/8numbers/icon2.svg"
            alt="project-icon"
            [width]="64"
            [height]="64"
            auxClassList="counter-image">
          </uni-image>
          <div fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="title margin-0">10,000+</p>
            <p class="subtitle margin-0">Projects</p>
          </div>
        </div>
        <div fxLayout="row" class="inner-count-container" fxLayoutAlign="flex-start center">
          <uni-image
            src="https://uni-common-assets.s3.amazonaws.com/Landingpage/8numbers/icon3.svg"
            alt="business-icon"
            [width]="64"
            [height]="64"
            auxClassList="counter-image">
          </uni-image>
          <div fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="title margin-0">100+</p>
            <p class="subtitle margin-0">Businesses</p>
          </div>
        </div>
        <div fxLayout="row" class="inner-count-container" fxLayoutAlign="flex-start center">
          <uni-image
            src="https://uni-common-assets.s3.amazonaws.com/Landingpage/8numbers/icon4.svg"
            alt="country-icon"
            [width]="64"
            [height]="64"
            auxClassList="counter-image">
          </uni-image>
          <div fxLayout="column" fxLayoutAlign="flex-start flex-start">
            <p class="title margin-0">80+</p>
            <p class="subtitle margin-0">Countries</p>
          </div>
        </div>
      </div>

      <ng-container *ngIf="jurors?.length">
        <div class="juror-section">
          <ng-container
            *ngFor="let juror of jurors.slice(0, smallView ? 16 : showCarousel ? 20 : 25)">
            <div class="tooltip relative" #jurorContainer>
              <div class="juror-container" fxLayout="row" fxLayoutAlign="center center">
                <uni-user-avatar
                  [user]="juror.user"
                  [viewProfile]="false"
                  avatarClass="juror-avatar">
                </uni-user-avatar>
              </div>
              <!-- <div class="info" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="8px">
                <span class="font-bolder sparro-grey-900 font-md text-center"
                  >{{ juror.user.first_name | titlecase }}
                  {{ juror.user.last_name | titlecase }}</span
                >
                <span class="sparro-grey-800 font-sm text-center">{{
                  juror.user.bio | titlecase
                }}</span>
              </div> -->
            </div>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="testimonial-section" fxLayout="column" fxLayoutAlign="center center">
    <ng-container *ngFor="let testimony of testimonies; let i = index">
      <div fxLayout="row" class="inner-container" [class.hidden]="i != testimonyVisibleIndex">
        <div fxLayout="column" class="content-section">
          <p class="content margin-0">{{ testimony.content }}</p>
          <div fxLayout="column" class="user-detail-section">
            <p class="username margin-0">{{ testimony.user.name }}</p>
            <p class="user-detail margin-0">{{ testimony.user.designation }}</p>
          </div>
        </div>
        <uni-image
          [src]="testimony.user.avatar_url"
          [alt]="testimony.user.name + ', ' + testimony.user.designation"
          [width]="360"
          [height]="360"
          auxClassList="testimonial-image">
        </uni-image>
        <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="24px" class="dot-container">
          <button class="fab-button mat-mini-fab" (click)="previousTestimonySlide()">
            <i class="icon-arrow-left-bold"></i>
          </button>
          <span class="font-md font-bold"
            >{{ testimonyVisibleIndex + 1 }}/{{ testimonies.length }}</span
          >
          <button class="fab-button mat-mini-fab" (click)="nextTestimonySlide()">
            <i class="icon-arrow-right-bold"></i>
          </button>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="partners-container">
    <div class="text-container" #memberCounterSection>
      <p class="section-title margin-0">{{ membersCount ? membersCount + ',000 +' : '0' }}</p>
      <p class="section-subtitle sparro-grey-800 margin-0">
        Architects & designers on our platform from some of the most trusted organizations in the
        industry
      </p>
    </div>

    <div class="partner-section">
      <ng-container *ngFor="let partner of partners; let i = index">
        <div fxLayout="column" fxLayoutAlign="center center" class="logo-container" #cards>
          <uni-image
            [src]="partner"
            [alt]="'partner-' + i"
            [height]="58"
            [width]="128"
            auxClassList="partner-image">
          </uni-image>
        </div>
      </ng-container>
    </div>
  </div>

  <section class="bottom-section">
    <div class="site-detail-container">
      <div class="membership-container width-100">
        <div class="text-section width-100">
          <div fxLayout="column">
            <h3 class="title margin-0">UNI Membership</h3>
            <p class="subtitle margin-0">
              Gain access to competitions without any late fee, publish unlimited content, 
              enjoy ad-free content, curate competitions, evaluate projects, 
              launch competitions and a lot more...
            </p>
          </div>
          <button mat-button class="button-section button-white">
            <a href="https://uni.xyz/about/designers/membership" class="no-decor">
              <div fxLayout="row">
                <span class="text sparro-grey-900">Know more</span>
              </div>
            </a>
          </button>
        </div>
        <uni-image
          [src]="membershipOptimizedImage.src"
          [alt]="membershipOptimizedImage.alt"
          [height]="385"
          [width]="370"
          auxClassList="membership-image">
        </uni-image>
      </div>
      <ng-container *ngIf="smallView; else carouselView">
        <div fxLayout="row" fxLayoutGap="16px" class="width-100">
          <ng-container *ngTemplateOutlet="carouselView"> </ng-container>
        </div>
      </ng-container>
      <ng-template #carouselView>
        <a href="https://uni.xyz/journal/exploring-design-like-never-before-desc-" class="no-decor">
          <div class="carousel-container overflow-hidden relative width-100">
            <div
              class="width-100"
              *ngFor="
                let feature of showCarousel
                  ? productFeatureCarousel.slice(0, productFeatureCarousel.length / 2)
                  : productFeatureCarousel;
                let i = index
              ">
              <uni-image
                [src]="feature"
                alt="UNI yearbook, Design explorer series 2021, Architecture, product design, urban design, graphic design, furniture design"
                auxClassList="slide"
                [height]="385"
                [class.hidden]="i != carouselVisibleIndex">
              </uni-image>
            </div>
            <div
              fxLayout="row"
              fxLayoutAlign="center center"
              fxLayoutGap="8px"
              class="dot-container width-100">
              <ng-container
                *ngFor="
                  let item of showCarousel
                    ? productFeatureCarousel.slice(0, productFeatureCarousel.length / 2)
                    : productFeatureCarousel;
                  let i = index
                ">
                <i [class.dot-fill]="i == carouselVisibleIndex" class="dot">a</i>
              </ng-container>
            </div>
          </div>
        </a>
        <a href="https://uni.xyz/journal/exploring-design-like-never-before-desc-" class="no-decor">
          <ng-container *ngIf="showCarousel">
            <div class="carousel-container overflow-hidden relative width-100">
              <div
                class="width-100"
                *ngFor="
                  let feature of productFeatureCarousel.slice(
                    productFeatureCarousel.length / 2,
                    productFeatureCarousel.length
                  );
                  let i = index
                ">
                <uni-image
                  [src]="feature"
                  alt="UNI yearbook, Design explorer series 2021, Architecture, product design, urban design, graphic design, furniture design"
                  auxClassList="slide"
                  [height]="385"
                  [class.hidden]="i != carouselVisibleIndex">
                </uni-image>
              </div>
              <div
                fxLayout="row"
                fxLayoutAlign="center center"
                fxLayoutGap="8px"
                class="dot-container width-100">
                <ng-container
                  *ngFor="
                    let item of productFeatureCarousel.slice(
                      productFeatureCarousel.length / 2,
                      productFeatureCarousel.length
                    );
                    let i = index
                  ">
                  <i [class.dot-fill]="i == carouselVisibleIndex" class="dot"></i>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </a>
      </ng-template>
    </div>

    <ng-container *ngIf="journals?.length">
      <div class="blogs-section">
        <div class="text-container width-100">
          <div
            fxLayout="row"
            [fxLayoutAlign]="(smallView ? 'center' : 'space-between') + ' center'">
            <h2 class="section-title margin-0">Recently added</h2>
            <a [href]="getJournalFeedUrl()" class="no-decor">
              <ng-container *ngIf="!smallView">
                <p class="link-text font-bold cursor-pointer margin-0">
                  Read more blogs <i class="icon-arrow-right-bold"></i>
                </p>
              </ng-container>
            </a>
          </div>
          <span class="section-subtitle sparro-grey-800"
            >Discover latest news and updates from house of UNI</span
          >
        </div>
        <div class="blogs-container">
          <div class="blog-section">
            <ng-container
              *ngFor="let blog of showCarousel ? journals : journals.slice(0, 3); let i = index">
              <a [href]="getJournalDetailUrl(blog)">
                <div class="blog-card img-gradient" #cards>
                  <uni-image
                    [src]="blog.image"
                    [alt]="blog.title"
                    [width]="500"
                    [height]="385"
                    auxClassList="bottom-blog-card-image">
                  </uni-image>
                  <p class="blog-title">{{ blog.title | truncate : 50 }}</p>
                </div>
              </a>
            </ng-container>
          </div>
        </div>
        <a [href]="getJournalFeedUrl()" class="no-decor">
          <ng-container *ngIf="smallView">
            <p class="link-text font-bold cursor-pointer margin-0">
              Read more blogs <i class="icon-arrow-right-bold"></i>
            </p>
          </ng-container>
        </a>
      </div>
    </ng-container>

    <div
      class="footer-container"
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="24px">
      <h2 class="title text-center">
        Be a part of the community of 200k +
        <p class="margin-0">architects and designers</p>
      </h2>
      <a [href]="getCompetitionFeedUrl()" mat-button class="no-decor button-section button-white-1">
        Explore Challenges
      </a>
    </div>
  </section>
  <uni-public-footer></uni-public-footer>
</div>
